import React, { CSSProperties, MouseEventHandler } from 'react';
import s from './Button.module.scss';

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  style?: CSSProperties | undefined;
  children: React.ReactNode;
  variant?: 'filled' | 'outlined';
};

function Button({
  onClick, disabled, style, children, variant = 'filled',
}: Props) {
  return (
    <button
      onClick={onClick}
      className={`${s.button} ${disabled ? s.button_disabled : ''} ${variant === 'outlined' ? s.button_outlined : s.button_filled}`}
      disabled={disabled}
      style={style}
      type="button"
    >
      {children}
    </button>
  );
}

export default Button;
