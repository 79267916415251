import React, { useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import fileDownload from 'js-file-download';
import DownloadIcon from '@mui/icons-material/Download';
import { env } from '../../env';
import Loader from '../Loader/Loader';

type Props = {
  projectId: string;
  entityId?: string;
  embedInfo: { id: string, title?: string, isImage: boolean, height?: string, width?: string, url?: string, fileType?: string, path?: string }[];
  type: 'metrics' | 'plots' | 'monitoring' | 'artifacts' | 'streamlit';
};

function EmbedContent({
  projectId, entityId, embedInfo, type,
}: Props) {
  const [contentLoaded, setContentLoaded] = useState<boolean>(false);

  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const theme = useMemo(() => createTheme({
    palette: {
      mode: 'dark',
    },
  }), []);

  const onLoadContentHandler = () => {
    setContentLoaded(true);
  };

  const url = (id: string) => {
    if (type === 'streamlit') {
      console.log('embedInfo', embedInfo);
      return embedInfo[0].url;
    }
    return `${env.REACT_APP_API_STORAGE_URL}/attachment/${type}/${projectId}/${entityId}/${id}`;
  };

  const iframeRender = (id: string, { height, width } : { height?: string, width?: string }) => (
    <>
      {contentLoaded ? null : <div style={{ position: 'absolute' }}><Loader /></div>}
      <iframe
        ref={iframeRef}
        allowFullScreen
        onLoad={onLoadContentHandler}
        loading="lazy"
        style={{
          border: 'none', maxWidth: type === 'streamlit' ? '' : 750, width: `${width || '100%'}`, height: `${height || '33vh'}`,
        }}
        title={`Embed for ${id}`}
        key={id}
        src={url(id)}
      />
    </>
  );

  const imageRender = (id: string, { height, width }: { height?: string, width?: string }) => (
    <>
      {contentLoaded ? null : <div style={{ position: 'absolute' }}><Loader /></div>}
      <img onLoad={onLoadContentHandler} style={{ width: `${width || '100%'}`, height: `${height || '33vh'}` }} src={url(id)} alt={`Embed for ${id}`} />
    </>
  );

  const handleDownload = (urlForDownload: string, filename: string) => {
    axios.get(urlForDownload, {
      responseType: 'blob',
      withCredentials: true,
    })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const documentRender = (id: string, path: string) => {
    const filename = path.split('/')[path.split('/').length - 1];

    return (
    // @ts-ignore
      <Button startIcon={<DownloadIcon />} size="small" variant="outlined" onClick={() => handleDownload(url(id), filename)}>
        Download artifact
      </Button>
    );
  };

  const renderContent = ({
    // eslint-disable-next-line @typescript-eslint/no-shadow
    isImage, fileType, id, height, width, path,
  }: { isImage: boolean; fileType: string | undefined; id: string; height: string | undefined; width: string | undefined; path: string | undefined }) => {
    if (!fileType) {
      return isImage ? imageRender(id, { height, width }) : iframeRender(id, { height, width });
    }

    if (fileType === 'document') {
      return documentRender(id, path as string);
    }

    if (fileType === 'image') {
      return imageRender(id, { height, width });
    }

    return iframeRender(id, { height, width });
  };

  return (
    <ThemeProvider theme={theme}>
      { embedInfo.length ? embedInfo.map(({
        // eslint-disable-next-line @typescript-eslint/no-shadow
        id, title, isImage, height, width, fileType, path,
      }) => (
        <Box sx={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '10px',
        }}
        >
          {fileType === 'document' ? (
            <Box sx={{
              display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'center', flexDirection: 'row', marginBottom: '10px',
            }}
            >
              <Typography component="h3" variant="h5" color="#fff" style={{ marginRight: '20px' }}>
                {title}
              </Typography>

              {
              renderContent({
                isImage, height, width, id, fileType, path,
              })
            }
            </Box>
          )
            : (
              <>
                <Typography component="h3" variant="h5" color="#fff">
                  {title}
                </Typography>

                {
              renderContent({
                isImage, height, width, id, fileType, path,
              })
            }
              </>
            )}

        </Box>
      )) : <span />}
    </ThemeProvider>

  );
}

export default EmbedContent;
