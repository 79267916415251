import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import StatusTag from '../../../../../components/StatusTag/StatusTag';
import { IMonitoring } from '../../../../../types/project/monitoring';
import s from './Overview.module.scss';

interface Props {
  data: IMonitoring;
}

function Overview({ data }: Props) {
  return (
    <div className={s.wrapper}>
      <div className={s.config_line}>
        <div className={s.title}>Metrics</div>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            { data.metrics?.map((metric: any) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item xs={2} sm={4} md={4} key={metric.id}>
                <StatusTag
                  key={`${metric.displayName}`}
                  usedValue={metric.value}
                  totalValue={2}
                  displayName={metric.displayName}
                  thresholdColor={metric?.thresholdColor}
                  width="100%"
                  height="68px"
                  type="2"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default Overview;
