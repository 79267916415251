import React from 'react';
import dayjs from 'dayjs';
import s from './Monitoring.module.scss';
import Button from '../../../../../components/Button/Button';

type Props = {
  id: string;
  index: number;
  title: string;
  description: string;
  hitDate: string;
  solved: boolean;
  solveAlert: (id: string) => void;
};

function AlertsInformationItem({
  id, title, description, hitDate, solved, index, solveAlert,
}: Props) {
  return (
    <tr className={s.table_row} key={id}>
      <td className={s.table_count}>{index}</td>
      <td className={s.table_count}>{title}</td>
      <td className={s.table_count}>{description}</td>
      <td className={s.table_count}>{dayjs(hitDate).toString()}</td>
      <td className={s.table_count}>{!solved ? <Button onClick={() => solveAlert(id)}>Solve</Button> : 'Solved'}</td>
    </tr>
  );
}

export default AlertsInformationItem;
