import React from 'react';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import GetMore from '../../../../components/GetMore/GetMore';
import Loader from '../../../../components/Loader/Loader';
import { IMonitoringItems } from '../../../../types/project/monitoring';
import s from './Monitoring.module.scss';
import MetricsInfo from '../../../../components/ExperimentComponents/MetricsInfo/MetricsInfo';
import { getFormattedDateFromTimeStamp } from '../../../../core/helpers/dateMethods';
import MonitoringStatus from '../../../../components/MonitoringComponents/MonitoringStatus/MonitoringStatus';
import MonitoringInfrastructure from '../../../../components/MonitoringComponents/MonitoringInfrastructure/MonitoringInfrastructure';

type Props = {
  data: IMonitoringItems;
  handleCheckAll: any;
  handleCheck: any;
  fetching: boolean;
  isExistScroll: boolean;
  handleOpenModal: (tab: string, id: string) => void;
  getMoreHandler: () => void;
  amountDatasets: number;
  totalCount: number | undefined;
  projectData: any;
};

function Monitoring({
  handleCheckAll,
  handleCheck,
  data,
  fetching,
  isExistScroll,
  getMoreHandler,
  amountDatasets,
  totalCount,
  projectData,
  handleOpenModal,
}: Props) {
  return (
    <div className={s.content}>
      {amountDatasets !== 0 && (
        <table className={s.table}>
          <thead className={s.thead}>
            <tr>
              <td>
                <CheckBox onChange={handleCheckAll} id="1" checked={false} />
              </td>
              <td>#</td>
              <td>Metrics</td>
              <td style={{ textAlign: 'center' }}>Data drift</td>
              <td style={{ textAlign: 'center' }}>Concept drift</td>
              <td style={{ textAlign: 'center' }}>Infrastructure</td>
              <td>Costs</td>
              <td>Last updated</td>
            </tr>
          </thead>
          <tbody className={s.tbody}>
            {data.monitoringInfo.map((monitoring, index) => (
              <tr className={s.table_row} key={monitoring.id}>
                <td>
                  <CheckBox
                    id={monitoring.id}
                    checked={monitoring.checked}
                    onChange={() => handleCheck(monitoring.id)}
                  />
                </td>
                <td className={s.table_count}>{index + 1}</td>
                <td>
                  <div
                    role="presentation"
                    onClick={() => handleOpenModal('description', monitoring.id)}
                  >
                    <MetricsInfo
                      limiter={2}
                      metrics={monitoring.metrics.map((metric, metricIndex) => ({
                        ...metric,
                        id: `${metricIndex}-${monitoring.id}`,
                      }))}
                    />
                  </div>
                </td>
                <td>
                  <div
                    role="presentation"
                    onClick={() => handleOpenModal('dataDrift', monitoring.id)}
                  >
                    <MonitoringStatus status={!monitoring.dataDrift ? 'GREEN' : 'RED'} />
                  </div>
                </td>
                <td>
                  <div
                    role="presentation"
                    onClick={() => handleOpenModal('conceptDrift', monitoring.id)}
                  >
                    <MonitoringStatus status={!monitoring.conceptDrift ? 'GREEN' : 'RED'} />
                  </div>
                </td>
                <td>
                  <div
                    role="presentation"
                    onClick={() => handleOpenModal('infrastructure', monitoring.id)}
                  >
                    <MonitoringStatus status={monitoring.infrastructure ? 'GREEN' : 'RED'} />
                  </div>
                </td>
                <td>
                  <div
                    role="presentation"
                    onClick={() => handleOpenModal('infrastructure', monitoring.id)}
                  >
                    <MonitoringInfrastructure
                      // data={monitoring}
                      marginBottom="8px"
                    />
                  </div>
                </td>
                <td className={s.table_text} style={{ whiteSpace: 'nowrap' }}>
                  <div
                    role="presentation"
                    onClick={() => handleOpenModal('description', monitoring.id)}
                  >
                    {getFormattedDateFromTimeStamp(monitoring.updatedDate)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {fetching && <Loader variant="down" />}
      {totalCount !== amountDatasets
        && !isExistScroll
        && amountDatasets < 10
        && !fetching
        && projectData && (
          <GetMore disabled={fetching} getMoreHandler={getMoreHandler} />
      )}
    </div>
  );
}

export default Monitoring;
