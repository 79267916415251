import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MonitoringService } from '../../../../../core/services/projects/Monitoring.service';
import s from '../Monitoring.module.scss';
import Navigation from '../../Navigation/Navigation';
import { oneProjectData } from '../../../../../core/redux/projects/selectors';
import ProjectTitle from '../../../../../components/ProjectTitle/ProjectTitle';
import AlertsInformationItem from './AlertsInformationItem';
import Button from '../../../../../components/Button/Button';

function AlertsInformationContainer() {
  const { projectId } = useParams();
  const projectData = useSelector(oneProjectData);

  const [alerts, setAlerts] = useState<{
    id: string;
    title: string;
    description: string;
    createdDate: string;
    solved: boolean;
  }[]>([]);

  useEffect(() => {
    if (projectId) {
      MonitoringService.getAlerts(projectId)
        .then((response) => {
          setAlerts(response.data.alerts);
        });
    }
  }, []);

  const solveAllAlerts = () => {
    MonitoringService.solveAllAlerts(projectId as string)
      .then(() => {
        MonitoringService.getAlerts(projectId as string)
          .then((response) => {
            setAlerts(response.data.alerts);
          });
      });
  };

  const solveAlertById = (id: string) => {
    MonitoringService.solveAlert(projectId as string, id)
      .then(() => {
        MonitoringService.getAlerts(projectId as string)
          .then((response) => {
            setAlerts(response.data.alerts);
          });
      });
  };

  return (
    <div className={s.wrapper}>
      <Navigation data={projectData} />
      <div className={s.header}>
        <ProjectTitle type="project" data={projectData} page="alerts information" />
        <Button onClick={() => solveAllAlerts()}>Solve all</Button>
      </div>
      <div className={s.content}>
        <table className={s.table}>
          <thead className={s.thead}>
            <tr>
              <td>#</td>
              <td>Title</td>
              <td style={{ textAlign: 'center' }}>Description</td>
              <td style={{ textAlign: 'center' }}>Hit date</td>
              <td style={{ textAlign: 'center' }}>Solve</td>
            </tr>

          </thead>
          <tbody className={s.tbody}>

            {alerts?.length ? alerts.map((alert, index) => (
              <AlertsInformationItem id={alert.id} index={index + 1} title={alert.title} description={alert.description} hitDate={alert.createdDate} solved={alert.solved} solveAlert={solveAlertById} />
            )) : <span />}
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default AlertsInformationContainer;
