import React, { useEffect, useMemo, useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Stack, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { MonitoringService } from '../../../../../core/services/projects/Monitoring.service';

interface Props {
  data: {
    projectId: string;
    id: string;
  };
}

function Graphs({ data }: Props) {
  const theme = useMemo(() => createTheme({
    palette: {
      mode: 'dark',
    },
  }), []);

  const [graphs, setGraphs] = useState<any[]>([]);

  const prepareDataForGraph = (graphData: any) => {
    const xAxis: Date[] = [];
    const yAxis: number[] = [];

    graphData.forEach((item: any) => {
      xAxis.push(new Date(item.timestamp));
      yAxis.push(item.value);
    });

    return {
      xAxis,
      yAxis,
    };
  };

  useEffect(() => {
    if (data.projectId) {
      MonitoringService.getGraphs(data.projectId, data.id)
        .then((response) => {
          setGraphs(response.data.result);
        });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {
        graphs?.length ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 2, sm: 8, md: 8 }}>
              {
                  graphs.map((graph) => (
                    <Grid item xs={2} sm={4} md={4} key={graph.id}>
                      <Typography variant="h6" color="#fff">{graph.displayName}</Typography>
                      <LineChart
                        key={graph.id}
                        xAxis={[{ scaleType: 'time', valueFormatter: (date) => `${dayjs(date, 'HH:mm:ss')}`, data: prepareDataForGraph(graph.data).xAxis }]}
                        series={[
                          {
                            data: prepareDataForGraph(graph.data).yAxis,
                          },
                        ]}
                        width={500}
                        height={300}
                        grid={{ vertical: true, horizontal: true }}
                      />
                    </Grid>
                  ))
            }

            </Grid>
          </Box>
        ) : (
          <Stack alignItems="center" justifyContent="center">
            <Typography variant="h6" color="#6a6c75">No data for the last 24 hours</Typography>
          </Stack>
        )
      }

    </ThemeProvider>
  );
}

export default Graphs;
