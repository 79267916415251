import { AppThunk, AppDispatch } from '../../store';
import { feedSlice } from './reducer';
import { DailyUpdateService } from '../../../services/projects/DailyUpdate.service';
import { StorageService } from '../../../services/storage/Storage.service';

const { getDailyUpdate, getComments, createComment } = DailyUpdateService;

const {
  setComments, setDailyUpdateCount, feedSuccess, startLoading, finishLoading, setErrors, updateCommentInput,
} = feedSlice.actions;

export const feedRequest = (projectId: string, page: number, limit: number, signal?: AbortSignal): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const response = await getDailyUpdate(projectId, page, limit, signal);
    dispatch(feedSuccess(response.data.dailyUpdates));
    dispatch(setDailyUpdateCount(response.data.pagination.count));
    dispatch(setErrors(false));
  } catch (e) {
    console.log(e);
    dispatch(setErrors(true));
  } finally {
    dispatch(finishLoading());
  }
};

export const addNewCommentRequest = (dailyUpdateId: string, text: string, signal?: AbortSignal): AppThunk => async () => {
  try {
    await createComment(dailyUpdateId, text, signal);
  } catch (error) {
    console.log(error);
  }
};

export const addNewDailyUpdate = (projectId: string, dailyUpdate: { text: string }, { data, needSendImage }: { data: FormData, needSendImage: boolean }): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const newDailyUpdate = await DailyUpdateService.createDailyUpdate(projectId, dailyUpdate);
    if (needSendImage) {
      await StorageService.uploadDailyUpdateImage(projectId, newDailyUpdate.data.newDailyUpdateId, data);
    }

    await feedRequest(projectId, 1, 10);
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(finishLoading());
  }
};

export const commentsRequest = (dailyUpdateId: string, signal?: AbortSignal): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const response = await getComments(dailyUpdateId, signal);
    dispatch(setComments(response.data.comments));
    dispatch(setErrors(false));
  } catch (e) {
    console.log(e);
    dispatch(setErrors(true));
  } finally {
    dispatch(finishLoading());
  }
};

export const updateComment = (id: string, value: string) => (dispatch: AppDispatch) => {
  dispatch(updateCommentInput({ id, value }));
};
