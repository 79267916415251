import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Container, Box,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MailLockIcon from '@mui/icons-material/MailLock';
import { oneProjectData } from '../../../../core/redux/projects/selectors';
import Navigation from '../Navigation/Navigation';
import { DailyUpdatesCard } from './DailyUpdatesCard';
import { getAvailablePostsSelector } from '../../../../core/redux/projects/feeds/selectors';
import { feedRequest } from '../../../../core/redux/projects/feeds/actions';
import { useAppDispatch } from '../../../../core/redux/store';
import { AddNewCommentModal } from './AddNewCommentModal';
import { AddNewDailyUpdateModal } from './AddNewDailyUpdateModal';
import ProjectTitle from '../../../../components/ProjectTitle/ProjectTitle';
import s from './DailyUpdates.module.scss';
import Button from '../../../../components/Button/Button';

export function DailyUpdatesContainer() {
  const dispatch = useAppDispatch();
  const projectData = useSelector(oneProjectData);
  const posts = useSelector(getAvailablePostsSelector);

  const [openComment, setOpenComment] = React.useState(false);
  const [openNewDailyUpdate, setOpenNewDailyUpdate] = React.useState(false);
  const [openDailyUpdateId, setOpenDailyUpdateId] = React.useState('');

  useEffect(() => {
    if (projectData) {
      dispatch(feedRequest(projectData.id, 1, 10));
    }
  }, [projectData]);

  const handleOpenNewDailyUpdateModal = () => {
    setOpenNewDailyUpdate(true);
  };

  const theme = useMemo(() => createTheme({
    palette: {
      mode: 'dark',
    },
  }), []);

  const addNewCommentHandler = (dailyUpdateId: string) => {
    setOpenDailyUpdateId(dailyUpdateId);
    setOpenComment(true);
  };

  const isAddNewCommentModalClosed = () => {
    setOpenDailyUpdateId('');
    setOpenComment(false);
  };

  const isNewDailyUpdateModalClosed = () => {
    setOpenNewDailyUpdate(false);
  };

  return (
    <>
      <Navigation data={projectData} />
      <div className={s.header}>
        <ProjectTitle type="project" data={projectData} page="daily updates" />
        <Button onClick={() => handleOpenNewDailyUpdateModal()}>Add new post</Button>
      </div>
      <Container maxWidth="lg">
        <Box style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
        }}
        >
          {posts.length === 0 && (
          <Box className={s.no_posts}>

            <MailLockIcon sx={{ fontSize: 60 }} />
            There are no posts
          </Box>
          )}

          {(posts.map((dailyUpdate) => <DailyUpdatesCard projectId={projectData.id} key={dailyUpdate.id} id={dailyUpdate.id} post={dailyUpdate} addNewCommentHandler={addNewCommentHandler} />))}
        </Box>

        <ThemeProvider theme={theme}>
          <AddNewDailyUpdateModal isModalTriggered={openNewDailyUpdate} closeTriggered={isNewDailyUpdateModalClosed} />

          <AddNewCommentModal isModalTriggered={openComment} dailyUpdateId={openDailyUpdateId} closeTriggered={isAddNewCommentModalClosed} />
        </ThemeProvider>
      </Container>
    </>
  );
}
