import api from '../../api';

export const ExperimentService = {
  getProjectExperiments: (
    projectId: string,
    display?: boolean,
    page?: number,
    limit?: number,
    signal?: AbortSignal,
  ) => api(
    'GET',
    [],
    `/view/projects/${projectId}/experiments?page=${page}&limit=${limit}`,
    {},
    signal,
  ),
  getExperimentMetrics: (
    projectId: string,
    experimentId: string,
  ) => api(
    'GET',
    [],
    `/view/projects/${projectId}/experiments/${experimentId}/metrics`,
  ),
  getExperimentConfiguration: (
    projectId: string,
    experimentId: string,
  ) => api(
    'GET',
    [],
    `/view/projects/${projectId}/experiments/${experimentId}/configuration`,
  ),
  getExperimentDatasets: (
    projectId: string,
    experimentId: string,
  ) => api(
    'GET',
    [],
    `/view/projects/${projectId}/experiments/${experimentId}/data`,
  ),
  getExperimentInfrastructure: (
    projectId: string,
    experimentId: string,
  ) => api(
    'GET',
    [],
    `/view/projects/${projectId}/experiments/${experimentId}/infrastructure`,
  ),
  getExperimentCode: (
    projectId: string,
    experimentId: string,
  ) => api(
    'GET',
    [],
    `/view/projects/${projectId}/experiments/${experimentId}/code`,
  ),
  archiveExperiment: (experimentId: string) => api('PATCH', [], `/experiments/${experimentId}/archive`),
};
