import React, { useState, useEffect } from 'react';
import { defineCurrency } from '../../core/helpers/textMethods';
import s from './StatusTag.module.scss';

type Props = {
  usedValue: number | undefined | null;
  totalValue: number | undefined | null;
  height?: string;
  width?: string;
  type?: '2' | 'metrics';
  thresholdColor?: 'grey' | 'red' | 'green';
  displayName?: string;
  currency?: string;
};

function StatusTag({
  usedValue,
  totalValue,
  height,
  width,
  thresholdColor,
  type,
  displayName,
  currency,
}: Props) {
  const [colors, setColors] = useState({
    backgroundColor: '#4E4E52',
    textColor: '',
  });

  const prepareUsedValue = (value: number | undefined | null) => {
    if (Number.isInteger(value) || typeof value === 'string') {
      return value;
    }
    return value?.toFixed(2) || '-';
  };

  const checkStatus = () => {
    if (thresholdColor) {
      if (thresholdColor === 'red') {
        setColors({
          ...colors,
          backgroundColor: '#F51D44',
          textColor: '#FFFFFF',
        });
      } else if (thresholdColor === 'green') {
        setColors({
          ...colors,
          backgroundColor: '#57DAD7',
          textColor: '#333333',
        });
      } else {
        setColors({
          ...colors,
          backgroundColor: '#6b6e7b',
          textColor: '#FFFFFF',
        });
      }
    } else if (usedValue && totalValue) {
      if (usedValue / totalValue >= 0.5 && usedValue / totalValue <= 1) {
        setColors({
          ...colors,
          backgroundColor: '#57DAD7',
          textColor: type === '2' ? '#000000' : '#333333',
        });
      } else if (usedValue / totalValue > 1) {
        setColors({
          ...colors,
          backgroundColor: '#F51D44',
          textColor: '#FFFFFF',
        });
      } else {
        setColors({
          ...colors,
          backgroundColor: '#FFD600',
          textColor: type === '2' ? '#000000' : '#333333',
        });
      }
    }
  };

  useEffect(() => {
    checkStatus();
  }, [usedValue, totalValue]);

  if (type === '2') {
    return (
      <div
        className={s.status_tag_2}
        style={{
          backgroundColor: colors.backgroundColor,
          color: colors.textColor,
          height,
          width,
        }}
      >
        <div>{displayName}</div>
        <div className={s.value_2}>
          {
            Number(usedValue) ? `${prepareUsedValue(usedValue)}` : `${usedValue || '-'}`
          }
        </div>
      </div>
    );
  }
  if (type === 'metrics') {
    return (
      <span
        className={s.status_tag}
        style={{
          backgroundColor: colors.backgroundColor,
          color: colors.textColor,
          height,
          width,
        }}
      >
        {`${currency ? defineCurrency(currency) : ''}${
          Number(usedValue) ? `${prepareUsedValue(usedValue)}` : `${usedValue || '-'}`
        }`}
      </span>
    );
  }
  return (
    <span
      className={s.status_tag}
      style={{
        backgroundColor: colors.backgroundColor,
        color: colors.textColor,
        height,
        width,
      }}
    >
      {`${currency ? defineCurrency(currency) : ''}${
        prepareUsedValue(usedValue)
      } / ${currency ? defineCurrency(currency) : ''}${totalValue || '-'}`}
    </span>
  );
}

export default StatusTag;
