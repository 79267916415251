import axios, { AxiosError } from 'axios';
import { env } from '../env';

const baseURL = env.REACT_APP_API_URL;

const api = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  data: any,
  url: string,
  additionalHeaders?: { [key: string]: string },
  signal?: any,
  cancelToken?: any,
) => {
  try {
    const GETheaders = {
      'Content-Type': 'application/json',
      ...additionalHeaders,
    };
    const headers = {
      ...additionalHeaders,
    };
    const res = method === 'GET'
      ? await axios({
        signal,
        method,
        url: baseURL + url,
        headers: GETheaders,
        withCredentials: true,
        cancelToken,
      })
      : await axios({
        signal,
        method,
        url: baseURL + url,
        headers,
        withCredentials: true,
        data,
      });

    return res;
  } catch (err: any) {
    if ([401].includes(err.response.status)) {
      window.localStorage.removeItem('persist:root');
      window.location.href = '/login';
    }

    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<any>;
      if (serverError && serverError.response) {
        return serverError.response.data;
      }
    }
    return { error: 500 };
  }
};

export default api;
